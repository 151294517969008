import React, { useEffect, useState } from "react";
import classnames from "classnames/bind";
import styles from "./index.module.scss";
import { Input, InputNumber, Button, message, Modal, Select, Form } from "antd";
import { calcRestDay, parseSearch, randomId } from "../../utils";
import { getExistTotal, getWxpaySubject } from "../../api/wxpaySubject";
import { payUnifiedOrderWxpaySubject } from "../../api/wxpay";
import {
  getWxAurhorizeUrl,
  registerWXUserInfoByFuWuHaoCode,
  wxpayUnifiedorder,
} from "../../api/third";
import { addWxpayOrder, checkWxUserPaid, setWxpayOrderStatus } from "../../api/wxpayOrder";
import { ENV } from "../../config/env";
import { devWxUserInfo } from "./dev";
import { InfoCircleOutlined } from "@ant-design/icons";
import { formControlMode } from "../PaySubjectManageAdd/config";
import FormControl from "../PaySubjectManageAdd/FormControl";

const cn = classnames.bind(styles);

const defaultCoverImage = require("../../assets/default-cover.jpg");
const k12LogoImage = require("../../assets/k12-logo.png");

const isDev = ENV === "development";

const MIN_CHILD_LEN = 1;
const MAX_CHILD_LEN = 10;
const UNLIMIT_PEOPLE_NUMBER = 99999; // 不限制报名人数

// 修改当前页面的标题
document.title = "凯思奥-微信支付报名系统";

const WxPayDetail = ({
  mode = 0,
  cover,
  qrcode,
  name,
  endDate = "",
  money,
  maxPeople,
  restDay,
}) => {
  const [info, setInfo] = useState({
    cover,
    qrcode,
    name,
    endDate,
    money,
    maxPeople,
    restDay,
    isEffect: restDay >= 0,
  });

  // 微信公众号用户openid （ 异步 来自微信公众号登录）
  const [wxUserInfo, setWxUserInfo] = useState("");

  // 用户填写（新模式）
  const [childNumber, setChildNumber] = useState(1);

  const [paid, setPaid] = useState(false);
  const [existTotal, setExistTotal] = useState(0); // 已付款人数(不展示)
  const [restPeopleNumber, setRestPeopleNumber] = useState(
    UNLIMIT_PEOPLE_NUMBER
  ); // 剩余可付款人数

  const [computedMoney, setComputedMoney] = useState(0);

  // 联系人唯一
  const [contactsFormRef] = Form.useForm();

  /**
   * 报名学生可多
   * Form.useForm  hook 之内在根级函数体内执行，这块暂时无法根据 change 事件
   *  在函数体内部完成动态新增，所以我们根级函数体 生成 max 份
   */
  const childrenFormRefList = [];
  for (let i = 0; i < MAX_CHILD_LEN; i++) {
    childrenFormRefList.push({
      id: randomId(),
      ref: Form.useForm()[0],
    });
  }

  const [useChildrenFormRefList, setUseChildrenFormList] = useState([]);

  useEffect(async () => {
    // 测试已支付
    // setPaid(true);

    // 线上用户模式
    if (!mode) {
      // 内联策略模式
      let loadingFn;

      if (isDev) {
        loadingFn = message.loading("开发环境测试，非微信跳转...", 0);
      } else {
        loadingFn = message.loading("凯思奥报名系统载入中...", 0);
      }

      // 获取基础信息
      const info = await toGetInfo();

      // 检测微信用户信息
      let info2;

      if (isDev) {
        info2 = devWxUserInfo;

        setWxUserInfo(info2);
      } else {
        info2 = await toGetOpenid();
      }

      // 检测用户是否已购买
      await toCheckWxUserPaid(info2.openid, info.id);

      // 检测已购买用户情况
      await toGetExistTotal(info.id, info.maxPeople);

      // 隐藏载入
      loadingFn();
    }
  }, []);

  // 当信息获取后
  useEffect(() => {
    handleChildNumberChange(1);
  }, [info]);

  async function toGetOpenid() {
    const { id, code } = parseSearch();
    // 先进行公众号登录
    if (!code) {
      const redirectUrl = window.encodeURIComponent(
        `http://admin.k12-code.com/wx-pay-detail?id=${id}`
      );

      const rs = await getWxAurhorizeUrl(redirectUrl);
      const { url } = rs;

      // 跳转地址进行公众号登录
      console.log(url);
      window.location.href = url;
    } else {
      // 由微信登录结束重定向跳转
      console.log("已获取 code ");

      // code 存在，拿openid
      // 1、注册用户公众号信息（已注册就直接返回openid)
      try {
        const rs = await registerWXUserInfoByFuWuHaoCode(code);
        console.log(rs);
        setWxUserInfo(rs);
        return rs;
      } catch (e) {
        // 重定向网站
        const { origin, pathname } = window.location;
        const { id } = parseSearch();
        window.location.href = `${origin}${pathname}?id=${id}`;
      }
    }
  }

  async function toCheckWxUserPaid(openid, subjectId) {
    try {
      await checkWxUserPaid(openid, subjectId);
      // 用户已付款
      setPaid(true);
    } catch (e) {
      console.log(e);
    }
  }

  async function toGetExistTotal(subjectId, maxPeople) {
    try {
      const existTotal = await getExistTotal(subjectId);
      // 已报名数量
      setExistTotal(existTotal);

      // 检测是否有余量
      if (maxPeople === 0) {
        // 不限制，定义余量超大
        setRestPeopleNumber(UNLIMIT_PEOPLE_NUMBER);
      } else {
        const restPeopleNumber = maxPeople - existTotal;
        setRestPeopleNumber(restPeopleNumber);
      }
    } catch (e) {
      console.log(e);
    }
  }

  // 响应到来自服务端的下单参数
  function onBridgeReadyPayDemo(tradeInfo) {
    const params = tradeInfo;

    WeixinJSBridge.invoke("getBrandWCPayRequest", params, function (res) {
      WeixinJSBridge.log(res.err_msg);
      //alert(res.err_code + res.err_desc + res.err_msg);
      if (res.err_msg == "get_brand_wcpay_request:ok") {
        message.success("您已支付完成，请长按下述二维码并加入");

        // 已付款
        setPaid(true);
      } else if (res.err_msg == "get_brand_wcpay_request:cancel") {
        message.warning("您已取消支付!");
      } else {
        message.warning("支付失败!");
      }
    });
  }

  // 本地模拟 - 响应到来自服务端的下单参数
  function onDevBridgeReadyPayDemo(tradeInfo) {
    const params = tradeInfo;
    message.success("本地测试 - 您已支付完成");
    // 已付款
    setPaid(true);
  }

  // 获取主题基本信息
  async function toGetInfo() {
    // 说明是用户模式
    const { id } = parseSearch();
    const info = await getWxpaySubject(id);
    setInfo({
      status: info.status,
      cover: info.cover,
      qrcode: info.qrcode,
      name: info.name,
      endDate: info.endDate,
      money: info.money,
      maxPeople: info.maxPeople,
      restDay: info.restDay,
      isEffect: info.restDay >= 0,
      contactsControlList: info.contactsControlList,
      childrenControlList: info.childrenControlList,
    });

    return info;
  }

  function handleChildNumberChange(length) {
    const l = childrenFormRefList.slice(0, length);

    // 同步
    setUseChildrenFormList(l);

    setChildNumber(length);
    // 解决JS精度问题
    setComputedMoney((info.money * 10 * length) / 10);
  }

  function handleChildNumberMinus() {
    const len =
      childNumber - 1 < MIN_CHILD_LEN ? MIN_CHILD_LEN : childNumber - 1;
    handleChildNumberChange(len);
  }

  function handleChildNumberPlus() {
    const len =
      childNumber + 1 > MAX_CHILD_LEN ? MAX_CHILD_LEN : childNumber + 1;
    handleChildNumberChange(len);
  }

  // 用户点击付款按钮
  async function handlePayClick() {
    try {
      const { isEffect } = info;

      // 报名已截止
      if (isEffect <= 0) {
        message.warn("报名已截止");
        return;
      }

      // 人员已满
      if (restPeopleNumber <= 0) {
        message.warn("本次活动报名人员已满，请关注下次活动吧~");
        return;
      }

      // 将姓名 + 联系方式 + 订单号 先同步至后台
      const { nickname, headimgurl, openid } = wxUserInfo;

      const amount = childNumber;

      // 获取付款凭证
      const { id: subjectId } = parseSearch();

      // 环境变量
      const env = ENV;

      // 校验表单
      // 校验联系人
      const contactsInfo = await contactsFormRef.validateFields();

      // 校验学生
      let childenInfoList = [];
      for (let i = 0; i < useChildrenFormRefList.length; i++) {
        const childInfo = await useChildrenFormRefList[i].ref.validateFields();
        childenInfoList.push(childInfo);
      }

      console.log("校验通过", contactsInfo, childenInfoList);

      // 生成后端关键参数
      const contacts_field_list = JSON.stringify([contactsInfo]);
      const children_field_list = JSON.stringify(childenInfoList);

      // 后台逻辑
      try {
        const tradeInfo = await payUnifiedOrderWxpaySubject({
          openid,
          subjectId,
          amount,
          env, // 环境参数（不同环境参数 决定服务端行为）
        });

        const {
          out_trade_no, // 内部订单号
        } = tradeInfo;

        const loadingFn = message.loading("载入中...", 0);

        // 1、先将订单同步至后台
        const orderId = await addWxpayOrder({
          nickname,
          headimgurl,
          openid,
          out_trade_no,
          child_number: childNumber,
          contacts_field_list,
          children_field_list,
          tbl_wxpay_subject_id: subjectId,
        });

        loadingFn();

        // 2、JSBridge 发起支付
        if (isDev) {
          onDevBridgeReadyPayDemo(tradeInfo);
        } else {
          // 如果金额为0
          if (computedMoney === 0) {
            // 直接设置为已付款
            await setWxpayOrderStatus(orderId, 1)
            // 直接显示已完成
            message.success("您已报名成功，请长按下述二维码并加入");
            // 已付款
            setPaid(true);
          } else {
            // 非0 触发微信支付
            onBridgeReadyPayDemo(tradeInfo);
          }
        }
      } catch (e) {
        console.log(e);
        message.warn(e.msg);
      }
    } catch (e) {
      // 如果是 表单校验类错误
      if (e.errorFields) {
        console.log(e);
        message.warn("存在未填项 或格式校验错误");
      } else {
        console.log(e);
        message.warn("未知错误", e);
      }
    }
  }

  // 块作用域
  {
    let {
      name,
      status,
      cover,
      qrcode,
      endDate,
      isEffect,
      money,
      maxPeople,
      restDay,
    } = info;

    if (!wxUserInfo) {
      return <p></p>;
    }

    if (status === -1) {
      message.warn("主题已关闭");
      return <p>主题已关闭</p>;
    } else if (status === 0) {
      message.warn("主题尚未发布");
      return <p>主题尚未发布</p>;
    } else {
      return (
        <div
          className={cn("ct")}
          style={{
            backgroundImage: `url(${cover})`,
          }}
        >
          <div
            style={{
              paddingTop: "120px",
              paddingBottom: "20px",
            }}
          >
            <div className={cn("ct-main")}>
              {!paid && (
                <>
                  <p fo-si-ss="" co-se="" di-fl="" pb-4="">
                    <InfoCircleOutlined co-wa="" fo-si-s="" pr-4="" />
                    1. 新用户请点击
                    <span co-da="" fo-si-ss="">
                      右下角 “使用完整服务”
                    </span>
                  </p>
                  <p fo-si-ss="" co-se="" ml-18="">
                    2. 报名成功后，请扫码进群联系相关老师；
                  </p>

                  <p className={cn("ct-navTitle")}>主题信息</p>

                  <div className={cn("ct-item")}>
                    <p>主题名称:</p>
                    <p>{name}</p>
                  </div>
                  <div className={cn("ct-item")}>
                    <p>报名截止:</p>
                    <p>{endDate.slice(0, 10)}</p>
                    <p co-se="" fo-si-s="">
                      {isEffect ? (
                        <span>(剩余{restDay}天)</span>
                      ) : (
                        <span co-da="">(已截止)</span>
                      )}
                    </p>
                  </div>

                  <div className={cn("ct-item")}>
                    <p>人数限制:</p>
                    {!maxPeople ? (
                      <p className={cn("maxPeople")}>不限制</p>
                    ) : (
                      <p className={cn("maxPeople")}>{maxPeople}</p>
                    )}

                    {restPeopleNumber <= 0 ? (
                      <span co-da="">(已满员)</span>
                    ) : (
                      <span fo-si-s="" co-se="">
                        {/* (已报名: <b>{existTotal}</b>人) */}
                      </span>
                    )}
                  </div>

                  <div className={cn("ct-item")}>
                    <p>主题金额:</p>
                    {computedMoney === 0 ? <p className={cn("money")}>0元(免费)</p> :
                    <p className={cn("money")}>{computedMoney}元</p>
                    }
                    
                  </div>

                  <div className={cn("ct-item")}>
                    <p>报名人数:</p>
                    <div className={cn("childNumber")}>
                      <Button type="primary" onClick={handleChildNumberMinus}>
                        -
                      </Button>
                      <InputNumber
                        className={cn("childInputNumber")}
                        min={MIN_CHILD_LEN}
                        max={MAX_CHILD_LEN}
                        value={childNumber}
                        onChange={handleChildNumberChange}
                      />
                    </div>
                    <Button type="primary" onClick={handleChildNumberPlus}>
                      +
                    </Button>
                  </div>

                  <p className={cn("ct-navTitle")}>联系人</p>

                  <FormControl
                    className={cn("ct-form")}
                    key={info.contactsControlList}
                    mode={formControlMode.using}
                    defaultValue={info.contactsControlList || []}
                    ref={contactsFormRef}
                  />
                  <p className={cn("ct-navTitle")}>报名学生 ({childNumber})</p>

                  {useChildrenFormRefList.map(({ id, ref }, index) => (
                    <>
                      <p
                        style={{
                          paddingTop: "16px",
                          fontWeight: "bold",
                        }}
                        co-wa-d=""
                      >
                        — 学生{index + 1} —
                      </p>
                      <FormControl
                        key={id}
                        className={cn("ct-form")}
                        mode={formControlMode.using}
                        defaultValue={info.childrenControlList || []}
                        ref={ref}
                      />
                    </>
                  ))}

                  {/* 支付按钮 */}
                  <div className={cn("pay")}>
                    {/* 必须具备微信用户信息才允许支付 */}
                    <div
                      className={cn("pay-btn", {
                        disabled: !isEffect || restPeopleNumber <= 0,
                      })}
                      onClick={handlePayClick}
                    >
                      {computedMoney === 0 ? "免费报名":`报名支付 ￥${computedMoney}`}
                    </div>
                  </div>
                </>
              )}

              {/* 如果已报名 */}
              {paid && (
                <>
                  <p className={cn("ct-navTitle")}>主题群二维码</p>

                  <div>
                    <img src={qrcode} className={cn("ct-qrcode")} />
                  </div>
                  <p co-su="" fo-si-s="">
                    您已报名成功，请“长按”上方二维码，并加入！
                  </p>
                </>
              )}
            </div>
          </div>
          <div className={cn("ct-footer")}>
            <img src={k12LogoImage} width="24" alt="" mr-8="" />
            凯思奥教育科技有限公司
          </div>
        </div>
      );
    }
  }
};

export default WxPayDetail;
