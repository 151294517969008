
const ENV = process.env.NODE_ENV

let apiBaseUrl

if(ENV==='development') {
  apiBaseUrl = 'http://localhost:3000'
  // apiBaseUrl = 'http://k12-code.com:3000' // 本地调试 微信接口
  // apiBaseUrl = 'https://passport.k12-code.com'
}else {
  apiBaseUrl = 'http://passport.k12-code.com'
}

export {
  ENV,
  apiBaseUrl
}